<template>
    <div>
        <el-form
            v-loading="dataLoading"
            :model="formData"
            :rules="rules"
            ref="formData"
            label-position="top"
            size="medium"
        >
            <div class="page-group-title"><span>基本信息</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="title" label="商品名称:">
                        <el-input v-model.trim="formData.title" placeholder="请输入商品名称" maxlength="200"></el-input>
                    </el-form-item>
                </el-col>
                <el-col v-if="id" :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="status" label="销售状态:">
                        <el-radio-group v-model="formData.status">
                            <el-radio :label="-1">下架</el-radio>
                            <el-radio :label="0" disabled>待上架</el-radio>
                            <el-radio :label="1">上架</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="price" label="原价(元):">
                        <el-input-number v-model="formData.price" :precision="2" :controls="false" :min="0" :max="99999999" placeholder="请输入原价"></el-input-number>
                    </el-form-item>
                </el-col>
                <el-col :xs="24" :sm="12" :lg="11" :xl="8">
                    <el-form-item prop="priceSell" label="售价(元):">
                        <el-input-number v-model="formData.priceSell" :precision="2" :controls="false" :min="0" :max="99999999" placeholder="请输入售价"></el-input-number>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="page-group-title"><span>商品图片</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :lg="22" :xl="24">
                    <el-form-item prop="photoList">
                        <image-manage :image-list.sync="formData.photoList" @onUploadSuccess="uploadImageHandle"></image-manage>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="page-group-title"><span>商品视频</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :lg="22" :xl="24">
                    <el-form-item prop="videoCoverList">
                        <video-manage :video-cover-list.sync="formData.videoCoverList" :video-list.sync="formData.videoList"></video-manage>
                    </el-form-item>
                </el-col>
            </el-row>
            <div class="page-group-title"><span>商品详情</span></div>
            <el-row :gutter="30">
                <el-col :xs="24" :lg="22" :xl="24">
                    <el-form-item prop="description" :show-message="formData.description ? false : true">
                        <tinymce v-if="reFresh" v-model="formData.description" :height="500" class="MT10"></tinymce>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-divider></el-divider>
        <div class="form-btns">
            <el-button @click="goBack">返回</el-button>
            <el-button type="primary"  @click="onSubmit" :loading="btnLoading">保存</el-button>
        </div>
    </div>
</template>

<script>
import Tinymce from '@/components/Tinymce'; 
import VideoManage from '@/components/VideoManage';
import ImageManage from '@/components/ImageManage';
const defaultFormData = {
    description: "",
    photoList: [],
    price: undefined,
    priceSell: undefined,
    title: "",
    videoCoverList: [],
    videoList: [],
    status: 0
}
export default {
    props:{
        id:{
            type: [String, Number],
            default: ''
        }
    },
    components:{Tinymce,ImageManage,VideoManage},
    data(){
        return{
            redirect: this.$route.query.redirect || '/goods/list',
            btnLoading: false,
            dataLoading: false,
            formData: {...defaultFormData},
            rules:{
                title: [{required: true, message: '请输入名称'}],
                price: [{required: true, message: '请输入原价'}],
                priceSell: [{required: true, message: '请输入销售价格'}],
                photoList: [{type: 'array', required: true, message: '请上传商品图片',trigger: 'change'}],
            },
            reFresh:false,
        }
    },
    mounted(){
        if( this.id ){
            this.getDataDetail();
        }else{
            this.reFresh = true;
        }
    },
    methods:{
        //返回
        goBack() {
            if(this.redirect){
                this.$router.push({
                    path: this.redirect,
                });
            }else{
                this.$router.go(-1);
            }
        },
        //获取编辑详情
        getDataDetail(){
            this.dataLoading = true;
            this.$request({
                url: '/api/ht/goods/info',
                method: "POST",
                params: {goodsId: this.id},
            })
                .then((res) => {
                    //处理成功回调
                    const { state, errmsg, result } = res.data;
                    if (state && state == 1) {
                        this.formData = {
                            ...defaultFormData,
                            ...result,
                        }
                    } else {
                        return this.$message.error(errmsg || "获取详情失败");
                    }
                })
                .catch(() => {
                    this.$message.error("获取详情失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                    this.reFresh = true;
                });
        },
        //上传图片成功后
        uploadImageHandle(){
            this.$refs["formData"].clearValidate('photoList');
        },
        //表单提交
        onSubmit(){
            this.$refs["formData"].validate((valid) => {
                const _url = this.id ? '/api/ht/goods/edit' : '/api/ht/goods/add'
                if (valid) {
                    this.btnLoading = true;
                    this.$request({
                        url: _url,
                        method: "POST",
                        data: {
                            ...this.formData,
                            goodsId: this.id || ""
                        },
                    })
                        .then((res) => {
                            //处理成功回调
                            const { state, msg } = res.data;
                            if (state && state == 1) {
                                this.goBack();
                            } else {
                                return this.$message.error(msg || "操作失败");
                            }
                        })
                        .catch(() => {
                            this.$message.error("操作失败,请重试");
                        })
                        .finally(() => {
                            this.btnLoading = false;
                        });
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>

</style>